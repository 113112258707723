import React from 'react';
import Global from "../../base/Global";
import './detail.css';
import Helpers from "../../base/Helpers";
import { getAPI } from "../../base/API";

export default class AddAllButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            generalSettings: Helpers.getGeneralSettings(),
            googleDataArray: [],
            showBtn: true,
            refreshAll: props.refreshAll,
            mapId: this.mapId
        };
        this.myArray = [];
        this.addAllToMyRoute = this.addAllToMyRoute.bind(this);
        this.execMultipleGetAddress = this.execMultipleGetAddress.bind(this);
    }

    getAddress(placeId) {
        if (placeId === null || placeId === "") {
            return;
        }

        getAPI(result => {
            const { data, error } = result;

            if (error) {
            }
            if (data) {
                if (data.data !== null) {
                    if (this.myArray.filter(e => e.reference === data.data.reference).length === 0) {
                        this.myArray.push(data.data);
                    }
                    Helpers.setGoogleDataSettings(placeId, data.data);

                    if (this.myArray.length === this.props.filteredMapData.length) {
                        this.setState({ showBtn: true });
                    }
                }
            }

        },Global.websiteBaseUrlWithoutProxy + Global.apiNewPath + Global.apiFunction + "getGooglePlace" +  Global.apiID + this.props.mapId + Global.apiG_ID + placeId);
    }

    execMultipleGetAddress() {
        let markerList = this.props.filteredMapData.filter(function (item) {
            return item !== undefined;
        });
        this.myArray = [];
        if (markerList != null && markerList.length > 0) {
            
            for (let i = 0; markerList.length > i; i++) {
                this.getAddress(markerList[i].reference);
            }
        }

        this.googleData = this.myArray;
        if (this.myArray.length === this.props.filteredMapData.length) {
            this.setState({ showBtn: true });
        }
    }

    // Add all marker to my route
    addAllToMyRoute() {
        let markerList = this.props.filteredMapData;
        const _this = this;
        for (let marker in markerList) {
            if (marker !== undefined) {
                setTimeout(function () {
                    _this.props.addRouteItem(markerList[marker]);
                }, 500);
            }
        }
    }

    UNSAFE_componentWillUpdate(nextProps) {
        if (nextProps.filteredMapData !== this.props.filteredMapData) {
           this.execMultipleGetAddress();
        }
    }

    render() {
        const { generalSettings, showBtn} = this.state;

        return (
            <div className="add-all">
                <button id="AddAllBtn" onClick={this.addAllToMyRoute} className={showBtn ? "rouded-with-border" : "hidden"}>
                    {generalSettings["Translates"]["AddAllToMyRoute"]}
                </button>
            </div>
        );
    }
}