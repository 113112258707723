import React from 'react';
import ReactHtmlParser, { } from "react-html-parser";
import Global from "../../base/Global";
import './detail.css';
import Helpers from "../../base/Helpers";
import { getAPI } from "../../base/API";
import StarRatingComponent from 'react-star-rating-component';
import AddAllButton from './AddAllButton';

export default class ListOfMarkers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            generalSettings: Helpers.getGeneralSettings(),
            isToShow: false,
            placeId: this.props.markerDetailData == null ? null : this.props.markerDetailData["reference"],
            address: null,
            rating: 0,
            googleData: null,
            googleDataArray: [],
            refreshAll: false,
            mapId: this.mapId
        };
        this.closeList = this.closeList.bind(this);
    }
  
    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {       
        if (nextProps.filteredMapData !== this.props.filteredMapData) {
            if (nextProps.selectedCategory === "seeAll") {
                this.setState({ isToShow: false });
            }
            else {                           
                const _this = this;
                setTimeout(function () {
                    _this.setState({ isToShow: true });                   
                    _this.setState({refreshAll: true});
                }, 500);
                this.props.toggleFooter()
            }
        }
        if (nextProps.markerDetailData !== this.props.markerDetailData) {           
            if (nextProps.markerDetailData["reference"] === "") {
                this.setState({ placeId: null });
                this.setState({ formatted_address: null });
                this.setState({ googleData: null });
            }
            else {                
                this.setState({ placeId: nextProps.markerDetailData["reference"] });
                //this.getAddress(nextProps.markerDetailData["reference"])
            }
        }
    }

    /*
    * Close/Hide the list of markers
    * */
    closeList() {        
        this.setState({ isToShow: false });
        this.setState({ refreshAll: true });
    }

    render() {
        const { generalSettings, isToShow, refreshAll } = this.state;
        const { selectedCategory, filteredMapData, handleDetail, selectedMarkerDetailData, selectedRoute, isToShowDetail, addRouteItem, routeArray } = this.props;

        if (selectedCategory === "seeAll" || isToShow === false) {
            return "";
        }

        return (
            <div data-to-show={isToShow ? "True" : "False"} data-type={selectedCategory} className="list-markers-container">
                <div className="header">
                    <h2 data-type={selectedCategory} className={selectedCategory} style={{ backgroundImage: "url(/content/images/Categories/ExploreMap/" + selectedCategory + ".svg)" }}>
                        {selectedCategory === null ? selectedRoute : generalSettings["Translates"][selectedCategory]}
                    </h2>
                    <span className="close" onClick={this.closeList}>X</span>
                </div>
                <div className="list-of-markers">
                    <div className="add-all-wrapper">
                        <AddAllButton
                            filteredMapData={filteredMapData}
                            routeArray={routeArray}
                            addRouteItem={addRouteItem}
                            refreshAll = {refreshAll}
                            mapId={this.props.mapId}
                        />
                    </div>
                    {filteredMapData.filter(function (item) {
                        return item !== undefined;
                    }).map(({ title, description, reference, imageUrl }) =>
                        <div key={title} className={isToShowDetail && selectedMarkerDetailData !== null && selectedMarkerDetailData["title"] === title ? "poi-item active" : "poi-item"} onClick={handleDetail.bind(this, title)}>
                            <Image
                                imageUrl={imageUrl}
                                placeId={reference}
                                mapId={this.props.mapId}
                            />
                            <div>
                                <h3>{title}</h3>
                                <Rating
                                    placeId={reference}
                                    mapId={this.props.mapId}
                                />
                                <div>
                                    {ReactHtmlParser(description.length > (window.innerWidth > 1200 ? 80 : 45) ? description.substring(0, (window.innerWidth > 1200 ? 80 : 45)) + "..." : description)}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

/*
* Detail Image
* */
class Image extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            googleImage: null
        };
    }

    componentDidMount() {
        if (this.props.imageUrl === false || this.props.imageUrl === "") {
            this.getGoogleImage(this.props.placeId);
        }
    }

    getGoogleImage(placeId) {
        if (placeId === null || placeId === "") {
            return;
        }

        let googleDataSettings = Helpers.getGoogleDataSettings(placeId);
        if (googleDataSettings != null) {
            this.setState({ googleData: googleDataSettings });
        }
        else {
            getAPI(result => {
                const { data, error } = result;

                if (error) {
                    console.log(error);
                }
                if (data) {
                    Helpers.setGoogleDataSettings(placeId, data.data);
                    if (data.data.photos.length) {
                        this.setState({ googleImage: data.data.photos[0].url });
                    }
                }

            }, Global.websiteBaseUrlWithoutProxy + Global.apiNewPath + Global.apiFunction + "getGooglePlace" +  Global.apiID + this.props.mapId + Global.apiG_ID + placeId);
        }
    }

    render() {
        const { imageUrl } = this.props;

        if (imageUrl !== null && imageUrl !== undefined && imageUrl !== false && imageUrl !== "") {
            return (
                <figure style={{ backgroundImage: "url(" + (imageUrl ? imageUrl : ReactHtmlParser(imageUrl)) + ")" }}> </figure>
            );
        }
        else if ((imageUrl === false || imageUrl === "") && this.state.googleImage !== null) {
            return (
                <figure style={{ backgroundImage: "url(" + this.state.googleImage + ")" }}> </figure>
            );
        }
        else {
            return "";
        }
    }
}

/*
* Rating
* */
class Rating extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            rating: 0
        };
    }

    componentDidMount() {
        this.getRating(this.props.placeId);
    }

    getRating(placeId) {
        if (placeId === null || placeId === "") {
            return;
        }

        let googleDataSettings = Helpers.getGoogleDataSettings(placeId);
        if (googleDataSettings != null) {
            this.setState({ googleData: googleDataSettings });
        }
        else {
            getAPI(result => {
                const { data, error } = result;

                if (error) {
                    console.log(error);

                    this.setState({ rating: 0 });
                }
                if (data) {
                    if (data.data.rating !== undefined) {
                        this.setState({ rating: data.data.rating });
                    }
                    else {
                        this.setState({ rating: 0 });
                    }
                }

            }, Global.websiteBaseUrlWithoutProxy + Global.apiNewPath + Global.apiFunction + "getGooglePlace" +  Global.apiID + this.props.mapId + Global.apiG_ID + placeId);
        }
    }

    render() {
        return (
            <div className="rating">
                <span>{Math.round(this.state.rating * 100) / 100}</span>
                <StarRatingComponent
                    name="rate2"
                    editing={false}
                    renderStarIcon={() => <span></span>}
                    starCount={5}
                    value={Math.round(this.state.rating)}
                    starColor={"#ffb400"}
                    emptyStarColor={"#000000"}
                />
            </div>
        );
    }
}
