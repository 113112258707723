import React from 'react';
import { Row } from 'reactstrap';
import { IntlContext } from "../../Utils/IntlProviderWrapper";
import { getAPI } from "../base/API.js";
import Global from "../base/Global";
import MapWithGroundOverlay from "../pages/map/Map";
import Loading from "../general/Loading";
import Footer from "../footer/Footer";
import Detail from "../pages/map/Detail";
import ListOfMarkers from "../pages/map/ListOfMarkers";
import ScreenSaver from "../screensaver/ScreenSaver";
import Helpers from "../base/Helpers";
import './main.css';

/* // todo - Do not remove the following comment */
/*global google*/

class Main extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            mapId: null,
            error: null,
            isLoading: true,
            isScreenSaver: false,
            generalSettings: Helpers.getGeneralSettings(),
            mapData: null,
            filteredMapData: null,
            availableCategories: null,
            markerDetailData: null,
            isToShowDetail: false,
            map: null,
            selectedCategory: "seeAll",
            selectedRoute: null,
            hotelMarker: null,
            center: {lat: 39.8262281, lng: -7.5007857}
        };

        this.handleDetail = this.handleDetail.bind(this);
        this.closeDetail = this.closeDetail.bind(this);
        this.getCategoryMarkers = this.getCategoryMarkers.bind(this);
        this.getRouteMarkers = this.getRouteMarkers.bind(this);
    }

    // Get global context
    static contextType = IntlContext; // get context

    async componentDidMount() {
        this.getMapIdByLanguage();
        this.setScreenSaver()
    }

    /*
    * Call the API do get the MAP ID by language
    * */
    getMapIdByLanguage() {
        // let url = Global.websiteUrl;

        // if(this.context.locale === "pt"){
        //     url += "pt-pt/";
        // }
        // else if(this.context.locale === "es"){
        //     url += "es/";
        // }
        
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                this.setState({isLoading: false, error: error});
                alert(error);
            }
            if (data) {
                if(data[this.context.locale.toUpperCase()] !== undefined && data[this.context.locale.toUpperCase()] !== null){
                    this.setState({mapId: data[this.context.locale.toUpperCase()], error: null, isLoading: false})
                    this.getMapById();
                }
                else{
                    this.setState({error: 'Does not exist map in this language', isLoading: false})
                }
            }
        }, Global.websiteBaseUrlWithoutProxy + Global.apiNewPath + Global.apiFunction + "getMapIdByLanguage" + Global.apiHotel + Global.customURL);
    }

    /*
    * Call the API to get all the MAP data
    * */
    getMapById() {
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                this.setState({loading: false, error: error});
                alert(error);
            }
            if (data) {
                this.setState({mapData: data});
                this.setState({filteredMapData: data[0]});

                for(let i = 0; i < data[0].length; i++) {
                    if (data[0][i].type === "Hotel") {
                        this.setState({hotelMarker: data[0][i]});
                        break;
                    }
                }
            }
        }, Global.websiteBaseUrlWithoutProxy + Global.apiNewPath + Global.apiFunction + "getMapNew" + Global.apiID + this.state.mapId);
    }
    
    /*
    * Set the filtered markers per category
    * */
    getCategoryMarkers(markerType){

        this.setState({selectedRoute: null});
        
        this.closeDetail();
        
        if(markerType === "seeAll"){
            this.setState({filteredMapData: this.state.mapData[0]});
            this.setState({selectedCategory: "seeAll"});
        }
        else{
            const filteredMarkers = this.state.mapData[0].filter(function (item) {
                return item.type === markerType;
            });
    
            this.setState({filteredMapData: filteredMarkers});
            this.setState({selectedCategory: markerType});
        }
        // Fit bounds
        const _this = this;
        setTimeout(function () {
            _this.handleMapMounted();
        }, 200);
    }
    
    /*
    * Set the filtered markers per route
    * */
    getRouteMarkers(title){
        const generalSettings = Helpers.getGeneralSettings();

        this.closeDetail();
        
        if("SuggestedRoutes" in generalSettings){
            const filteredRoute = generalSettings["SuggestedRoutes"].filter(function (item) {
                return item.title === title;
            });
            
            let markers = [];
            for(let i = 0; i < filteredRoute[0]["coordinates_of_the_route"].length; i++){
                const filteredMarker = this.state.mapData[0].filter(function (item) {
                    return item.reference === filteredRoute[0]["coordinates_of_the_route"][i]["coordinates"];
                });
                
                markers.push(filteredMarker[0]);
            }
            this.setState({selectedRoute: filteredRoute[0]["title"]});
            this.setState({selectedCategory: null});
            this.setState({filteredMapData: markers});

            // Fit bounds
            const _this = this;
            setTimeout(function () {
                _this.handleMapMounted();
            }, 200);
        }
    }
    
    /*
    * Get marker detail
    * */
    handleDetail(titleValue) {
      
        const indexOfArray = this.findWithAttr(this.state.mapData[0], "title", titleValue);
        
        if(indexOfArray === -1) return;

        const _this = this;
        _this.setState({markerDetailData: _this.state.mapData[0][indexOfArray]});
        
        setTimeout(function () {
            _this.setState({isToShowDetail: true});
        },200);
    }
    
    /*
    * Close detail action
    * */
    closeDetail(){
        this.setState({isToShowDetail: false});
    }

    /*
    * Find index of the array by property
    * */
    findWithAttr(array, attr, value) {
        for(var i = 0; i < array.length; i += 1) {
            if(array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }

    /*
    * Fit bound at the begin AND every time that the category is changed
    * */
    handleMapMounted = (map) => {
        if(map){
            this._map = map;
            this.setState({map: map});
        }
        else{
            this._map = this.state.map;
        }
        
        const bounds = new google.maps.LatLngBounds();

        this.state.filteredMapData.map(item => {
            if(item === undefined) {
                return;
            }
           return bounds.extend({lat: parseFloat(item.lat), lng: parseFloat(item.lng)});
        });
        this._map.fitBounds(bounds)

    };

    async setScreenSaver () {
        const _this = this;
        let time;
        let SAVE_TIMER = await this.state.generalSettings["SaverTimer"];
        // Events
        window.onload = resetTimer;
        document.onmousemove = resetTimer;
        document.onkeypress = resetTimer;
        
        function resetTimer() {
            clearTimeout(time);
            
            time = setTimeout(function () {
                _this.setState({isScreenSaver: true});
            }, SAVE_TIMER * 1000)
            
            if (time >= 0) {
                _this.setState({isScreenSaver: false});
            }
        }
    }
    
    render() {
        const {mapData, center, markerDetailData, isToShowDetail, filteredMapData, selectedCategory, isLoading, isScreenSaver, selectedRoute} = this.state;
       
        if(isLoading){
            return(
                <Row className="fuild-container">
                    <div className="map-container">
                        <Loading/>
                    </div>
                </Row>
            );
        }
        else if (isScreenSaver) {
            return (
                <ScreenSaver/>
            );
        }
        else if(mapData === null)
        {
            return null;
        }
        else{
            return (
                <Row className="fuild-container">
                    <div className="map-container">
                        
                        <Detail 
                            isToShowDetail={isToShowDetail}
                            closeDetail={this.closeDetail}
                            addRouteItem={this.props.addRouteItem}
                            routeArray={this.props.routeArray}
                            markerDetailData={markerDetailData}
                            mapId={this.state.mapId}
                        />
                        
                        <ListOfMarkers
                            selectedCategory={selectedCategory}
                            filteredMapData={filteredMapData}
                            handleDetail={this.handleDetail}
                            selectedMarkerDetailData={markerDetailData}
                            selectedRoute={selectedRoute}
                            isToShowDetail={isToShowDetail}
                            routeArray={this.props.routeArray}
                            addRouteItem={this.props.addRouteItem}
                            addAllToMyRoute={this.props.addAllToMyRoute}
                            googleData={this.props.googleData}
                            markerDetailData={markerDetailData}
                            toggleFooter={this.props.toggleFooter}
                            mapId={this.state.mapId}
                        />
                        
                        <MapWithGroundOverlay
                            googleMapURL={Global.googleMapsKey}
                            loadingElement={<div style={{height: `100%`}}/>}
                            containerElement={<div style={{height: `100%`}}/>}
                            mapElement={<div style={{height: `100%`}}/>}
                            /*CUSTOM*/
                            center={center}
                            markers={filteredMapData}
                            handleDetail={this.handleDetail}
                            onMapMounted={this.handleMapMounted}
                            hotelMarker={this.state.hotelMarker}
                        />
                    </div>
                    
                    <Footer
                        handleToggleModal={this.props.handleToggleModal}
                        markers={mapData[0]}
                        getCategoryMarkers={this.getCategoryMarkers}
                        selectedCategory={selectedCategory}
                        getRouteMarkers={this.getRouteMarkers}
                        selectedRoute={selectedRoute}
                        toggleFooter={this.props.toggleFooter}
                        isToShowFooter={this.props.isToShowFooter}
                    />
                    
                </Row>
            )
        }
    }
}

export default Main;
